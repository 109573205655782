import _map from "lodash/map";
import { call, fork, ForkEffect, put, select, takeLatest } from "redux-saga/effects";

import {
  getFavoritesOrder,
  getFilterRole,
  setFilterRoles,
  updateCurrentUserFavoritesOrder,
  waitForAllReports,
  waitForFavorites,
  waitForGroupedTags,
} from "@App/sagas/helpers/utils.sagas";

import { getSortedFavoriteReports } from "Helpers/reportsHelper";
import { dashboardDefaultRole } from "Helpers/utils";
import {
  DashboardActionTypes,
  getDashboardReports as getDashboardReportsAction,
  getDashboardReportsFailure,
  getDashboardReportsSuccess,
  IReorderFavoriteReportsAction,
  ISetFilterRolesAction,
} from "Pages/dashboard/dashboard.actions";
import { filterByFavorites, filterByTags } from "SP/reports/reports.filters";
import { IReport, IReportFields } from "SP/reports/reports.types";
import { TagGroupNames } from "SP/tags/tags.types";
import { IRootReducerState } from "Store/reducers";

export function* getDashboardReports() {
  try {
    const allReports = yield waitForAllReports();
    const favorites = yield waitForFavorites();
    const groupedTags = yield waitForGroupedTags();
    const filterRoles = yield select((state: IRootReducerState) => state.dashboard.filterRoles);

    const filterRolesIds = new Set<{ name: string; id: string }>();
    filterRoles.forEach((filterRole) => {
      const filterRoleId = groupedTags.Role.find((role) => role.name === filterRole)?.id || dashboardDefaultRole.id;
      filterRolesIds.add({ name: filterRole, id: filterRoleId });
    });

    const favoriteIds = _map(favorites, "reportId");

    const favoriteReports = filterByFavorites(allReports, favoriteIds);

    const favoriteOrders = yield call(getFavoritesOrder);
    const sortedFavoriteReports = getSortedFavoriteReports(favoriteOrders, favoriteReports);

    const roleRelatedReports: Record<string, IReport[]> = {};

    filterRolesIds.forEach((role) => {
      if (role.name === dashboardDefaultRole.label) {
        roleRelatedReports[role.name] = allReports;
        return;
      }

      const roleTagObject = { [TagGroupNames.Role]: [role.id] };
      roleRelatedReports[role.name] = filterByTags(allReports, roleTagObject);
    });

    yield put(getDashboardReportsSuccess(sortedFavoriteReports, roleRelatedReports));
  } catch (error: any) {
    yield put(getDashboardReportsFailure(error));
    throw error;
  }
}

export function* reorderFavoriteReports(action: IReorderFavoriteReportsAction) {
  try {
    const favoriteReportsIds = _map(action.favoriteReports, "id");
    yield call(updateCurrentUserFavoritesOrder, favoriteReportsIds);
  } catch (error) {
    throw error;
  }
}

export function* getRole() {
  yield call(getFilterRole);
}

export function* watchGetRole() {
  yield takeLatest(DashboardActionTypes.GET_FILTER_ROLE, getRole);
}

export function* watchGetReportsByDomain() {
  yield takeLatest(DashboardActionTypes.GET_DASHBOARD_REPORTS_REQUEST, getDashboardReports);
}

export function* requestDashboardUpdate(action: ISetFilterRolesAction) {
  yield call(setFilterRoles, action.filterRoles);
  yield put(getDashboardReportsAction());
}

export function* watchDashboardUpdates() {
  yield takeLatest([DashboardActionTypes.SET_FILTER_ROLES], requestDashboardUpdate);
}

export function* watchReorderFavoriteReports() {
  yield takeLatest(DashboardActionTypes.REORDER_FAVORITE_REPORTS, reorderFavoriteReports);
}

export default function* dashboardSagas(): Iterator<ForkEffect> {
  yield fork(watchGetReportsByDomain);
  yield fork(watchDashboardUpdates);
  yield fork(watchReorderFavoriteReports);
  yield fork(watchGetRole);
}
