import React, { useEffect, useState } from "react";

import { ReactComponent as collapseIcon } from "@App/assets/collapse.svg";
import { ReactComponent as expandIcon } from "@App/assets/expand.svg";
import { ReactComponent as closeIcon } from "@epam/assets/icons/common/navigation-close-24.svg";
import { CheckboxGroup, IconButton, LinkButton, ScrollBars, SearchInput, Text, Tooltip } from "@epam/loveship";

import ExpandableMenu from "Components/expandableMenu";
import useCollapse from "Hooks/useCollapse";
import { useMobileData } from "Hooks/useMobile";
import { useFiltersQuery } from "Hooks/useQuery";
import { useReportsMethods } from "Hooks/useReports";
import { DashboardFilterTabs } from "Pages/dashboard/dashboard-domain-filters/dashboard-domain-filters";
import type { IFilterTypes } from "Pages/reports-browser/reports-filters.actions";

import { useFilterContext } from "../filter-context";
import type { ITimePeriod } from "../reports-filters.reducer";
import ReportBrowsersFilterCheckboxGroup from "./report-browsers-filter-checkbox-group";
import ReportBrowsersFilterMetrics from "./report-browsers-filter-metrics";
import { ReportBrowsersFilterOwners } from "./report-browsers-filter-owners";
import { ReportBrowsersFilterTimePeriod } from "./report-browsers-filter-time-period";
import { ALL, IFilterSectionItem } from "./reports-browser-filter.types";

export function isAll(id: string): boolean {
  return id === ALL.id;
}

interface IReportsBrowserFilterSections {
  onClose: () => void;
}

const emptyFilters: IFilterTypes = {
  selectedTags: [],
  selectedOwners: [],
  filterBy: null,
  timePeriod: null,
  searchText: null,
};

export function isAtLeastOneFilterPresent(
  tags: Set<string>,
  owners: number[],
  filterBy: string[],
  timePeriod: ITimePeriod,
): boolean {
  return tags.size > 0 || owners.length > 0 || filterBy?.length > 0 || !!timePeriod;
}

const ReportsBrowserFilterSections: React.FC<IReportsBrowserFilterSections> = ({ onClose: handleModalClose }) => {
  const [filtersSearch, setFiltersSearch] = useState("");
  const { isMobile } = useMobileData();
  const {
    selectedTags,
    selectedOwners,
    filterBy,
    timePeriod,
    searchText,
    groupedTagOptions,
    ownerOptions,
    setSelectedTags,
    setSelectedOwners,
    setFiltersBy,
    setTimePeriod,
    setAllFilters,
    getSelectedTagsToRender,
    getUsersByIds,
  } = useFilterContext();
  const { hasParams, setQueryByFilters, getFiltersFromQuery } = useFiltersQuery();
  const { getFilteredReports } = useReportsMethods();

  const {
    state: sectionsCollapsed,
    toggle: handleToggleCollapsed,
    isAllCollapsed,
    toggleAll: handleToggleCollapsedAll,
  } = useCollapse(Object.keys(groupedTagOptions), true);

  useEffect(() => {
    if (hasParams && Object.keys(groupedTagOptions).length) {
      const filtersFromQuery = getFiltersFromQuery(groupedTagOptions);
      setAllFilters(filtersFromQuery, false);
    }
  }, [JSON.stringify(groupedTagOptions)]);

  useEffect(() => {
    if (!hasParams && (searchText || isAtLeastOneFilterPresent(selectedTags, selectedOwners, filterBy, timePeriod))) {
      setQueryByFilters({
        selectedTags: getSelectedTagsToRender(Array.from(selectedTags)),
        selectedOwners: getUsersByIds(selectedOwners),
        filterBy,
        timePeriod,
        searchText,
      });
      // Update 'filteredReports' between redirects if some report created or edited and
      // should be included or excluded from the list!
      getFilteredReports();
    }
  }, []);

  const handleClearAll = () => {
    // Reset all filters except 'searchText'
    setAllFilters({ ...emptyFilters, searchText });
    handleModalClose();
  };

  const getFilterChangeHandler = (oldValues: string[]) => (newValues: string[]) => {
    const newSelectedTags: Set<string> = new Set(selectedTags);
    oldValues.forEach((id) => newSelectedTags.delete(id));
    newValues.forEach((id) => newSelectedTags.add(id));
    setSelectedTags(Array.from(newSelectedTags));
  };

  const renderExpandableMenu = (sectionId: string, child: JSX.Element, menuText = sectionId) => {
    return (
      <ExpandableMenu
        key={sectionId}
        type={sectionId}
        menuText={menuText}
        isCollapsed={sectionsCollapsed[sectionId]}
        onToggleCollapsed={() => handleToggleCollapsed(sectionId)}
      >
        {child}
      </ExpandableMenu>
    );
  };

  const renderFilterSection = (sectionId: string, sectionItems: IFilterSectionItem[]) => {
    const selectedItems = sectionItems.filter((item) => selectedTags.has(item.id)).map((item) => item.id);

    if (sectionId === "Metric") {
      return (
        <ReportBrowsersFilterMetrics
          sectionItems={sectionItems}
          selectedItems={selectedItems}
          onSelectFilters={getFilterChangeHandler(selectedItems)}
        />
      );
    }

    return (
      <ReportBrowsersFilterCheckboxGroup
        showToggleAll={!filtersSearch}
        sectionItems={sectionItems}
        selectedItems={selectedItems}
        onSelectFilters={getFilterChangeHandler(selectedItems)}
      />
    );
  };

  const renderFilterSections = (sectionId: string) => {
    const sectionItems = groupedTagOptions[sectionId].filter(
      (sectionItem) =>
        sectionItem.name.toLowerCase().includes(filtersSearch.toLowerCase()) || selectedTags.has(sectionItem.id),
    );

    if (!sectionItems.length) return null;

    return renderExpandableMenu(sectionId, renderFilterSection(sectionId, sectionItems));
  };

  return (
    <div className="reports-browser-filter__sections">
      <div className="reports-browser-filter__block reports-browser-filter__block--top">
        <div className="flex items-center justify-between">
          <Text color="night700" size="24" fontSize="18" font="sans-semibold">
            Filtration
          </Text>
          <div className="flex items-center">
            {isAtLeastOneFilterPresent(selectedTags, selectedOwners, filterBy, timePeriod) && (
              <LinkButton size="24" font="sans" fontSize="14" caption="Clear all" onClick={handleClearAll} />
            )}
            <Tooltip placement="auto" content={isAllCollapsed() ? "Expand all" : "Collapse all"}>
              <IconButton
                cx="reports-browser-filter__collapse-all"
                icon={isAllCollapsed() ? expandIcon : collapseIcon}
                onClick={handleToggleCollapsedAll}
              />
            </Tooltip>
            {isMobile && <IconButton cx="reports-browser-filter__close" icon={closeIcon} onClick={handleModalClose} />}
          </div>
        </div>
        <SearchInput
          cx="filters-search"
          placeholder="Search tags"
          value={filtersSearch}
          onValueChange={setFiltersSearch}
        />
      </div>
      <div className="reports-browser-filter__block reports-browser-filter__block--content">
        <ScrollBars>
          <div className="sections-wrapper">
            <CheckboxGroup
              cx="reports-browser-filter__checkbox-group"
              items={[
                { id: DashboardFilterTabs.favorite, name: "Favorite" },
                { id: DashboardFilterTabs.certified, name: "Certified" },
              ]}
              value={filterBy}
              onValueChange={setFiltersBy}
              direction="vertical"
            />
            {Object.keys(groupedTagOptions).map(renderFilterSections)}
            {!!Object.keys(groupedTagOptions).length &&
              renderExpandableMenu(
                "TimePeriod",
                <ReportBrowsersFilterTimePeriod timePeriod={timePeriod} onSetTimePeriod={setTimePeriod} />,
                "Modification Date",
              )}
            {!!Object.keys(groupedTagOptions).length &&
              renderExpandableMenu(
                "Owner",
                <ReportBrowsersFilterOwners
                  ownerOptions={ownerOptions}
                  selectedOwners={selectedOwners}
                  onSetSelectedOwners={setSelectedOwners}
                />,
              )}
          </div>
        </ScrollBars>
      </div>
    </div>
  );
};

export default ReportsBrowserFilterSections;
