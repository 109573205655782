import { ITimePeriod } from "Pages/reports-browser/reports-filters.reducer";

export enum ReportsFiltersActionTypes {
  SET_FILTER_TAGS = "[REPORTS FILTERS] SET FILTER TAGS",
  RESET_FILTER_TAGS = "[REPORTS FILTERS] RESET FILTER TAGS",

  SET_SEARCH_TEXT = "[REPORTS FILTERS] SET SEARCH TEXT",

  SET_SORT = "[REPORTS FILTERS] SET SORT",

  SET_FILTER_OWNERS = "[REPORTS FILTERS] SET FILTER OWNERS",

  SET_FILTER_BY = "[REPORTS FILTERS] SET FILTER BY",

  SET_FILTER_TIME_PERIOD = "[REPORTS FILTERS] SET FILTER TIME PERIOD",

  SET_ALL_FILTERS = "[REPORTS FILTERS] SET ALL FILTERS",

  SET_PRESELECTED_ROLES = "[REPORTS FILTERS] SET PRESELECTED ROLES",
}

export interface ISetFilterTagsAction {
  type: ReportsFiltersActionTypes.SET_FILTER_TAGS;
  tagIds: string[];
}

export function setFilterTags(tagIds: string[]): ISetFilterTagsAction {
  return {
    type: ReportsFiltersActionTypes.SET_FILTER_TAGS,
    tagIds,
  };
}

export interface IResetFilterTagsAction {
  type: ReportsFiltersActionTypes.RESET_FILTER_TAGS;
}

export function resetFilterTags(): IResetFilterTagsAction {
  return {
    type: ReportsFiltersActionTypes.RESET_FILTER_TAGS,
  };
}

export interface ISetSearchTextAction {
  type: ReportsFiltersActionTypes.SET_SEARCH_TEXT;
  search: string;
}

export function setSearchText(search: string): ISetSearchTextAction {
  return {
    type: ReportsFiltersActionTypes.SET_SEARCH_TEXT,
    search,
  };
}

export interface ISetFilterByAction {
  type: ReportsFiltersActionTypes.SET_FILTER_BY;
  filterBy: string[];
}

export function setFilterBy(filterBy: string[]): ISetFilterByAction {
  return {
    type: ReportsFiltersActionTypes.SET_FILTER_BY,
    filterBy,
  };
}

export interface IFilterTypes {
  filterBy: string[];
  selectedTags: string[];
  selectedOwners: number[];
  timePeriod: ITimePeriod;
  searchText: string;
}

export interface ISetAllFiltersAction {
  type: ReportsFiltersActionTypes.SET_ALL_FILTERS;
  filters: IFilterTypes;
}

export function setAllFilters(filters: IFilterTypes): ISetAllFiltersAction {
  return {
    type: ReportsFiltersActionTypes.SET_ALL_FILTERS,
    filters,
  };
}

export interface ISetFilterOwnersAction {
  type: ReportsFiltersActionTypes.SET_FILTER_OWNERS;
  ownerIds: number[];
}

export function setFilterOwners(ownerIds: number[]): ISetFilterOwnersAction {
  return {
    type: ReportsFiltersActionTypes.SET_FILTER_OWNERS,
    ownerIds,
  };
}

export interface ISetFilterTimePeriodAction {
  type: ReportsFiltersActionTypes.SET_FILTER_TIME_PERIOD;
  timePeriod: ITimePeriod;
}

export function setFilterTimePeriod(timePeriod: ITimePeriod): ISetFilterTimePeriodAction {
  return {
    type: ReportsFiltersActionTypes.SET_FILTER_TIME_PERIOD,
    timePeriod,
  };
}

export interface ISetPreselectedRoles {
  type: ReportsFiltersActionTypes.SET_PRESELECTED_ROLES;
  values: string[];
}

export function setPreselectedRoles(values: string[]): ISetPreselectedRoles {
  return {
    type: ReportsFiltersActionTypes.SET_PRESELECTED_ROLES,
    values,
  };
}

export type IReportsFiltersActionTypes =
  | ISetFilterTagsAction
  | IResetFilterTagsAction
  | ISetSearchTextAction
  | ISetFilterByAction
  | ISetAllFiltersAction
  | ISetFilterOwnersAction
  | ISetFilterTimePeriodAction
  | ISetPreselectedRoles;
