import React from "react";

import { ReactComponent as ChevronDown } from "@epam/assets/icons/common/navigation-chevron-down-12.svg";
import { ReactComponent as ChevronUp } from "@epam/assets/icons/common/navigation-chevron-up-12.svg";
import { Badge, IconContainer, Spinner } from "@epam/loveship";
import { PickerTogglerProps } from "@epam/uui-components";

import withNaming from "Helpers/bemClassname";
import { IRole } from "Helpers/utils";

import "./filter-role-toggler.scss";

const cn = withNaming("filter-role-toggler");

export const FilterRoleToggler = (props: PickerTogglerProps<IRole, unknown> & { loading: boolean }) => {
  return (
    <div {...props} className={cn("")}>
      <div className={cn("body")}>
        {props.loading ? (
          <div className={cn("spinner-box")}>
            <Spinner cx={cn("spinner")} />
          </div>
        ) : (
          <>
            <div className={cn("text")}>
              {props.selection?.[0]?.value?.label || <span className={cn("placeholder")}>{props.placeholder}</span>}
            </div>
            {props.selection?.length > 1 && (
              <div className={cn("badge-container")}>
                <Badge cx={cn("badge")} count={props.selection?.length - 1} color="night100" fill="solid" />
              </div>
            )}
          </>
        )}
      </div>
      <IconContainer icon={props.isOpen ? ChevronUp : ChevronDown} />
    </div>
  );
};
