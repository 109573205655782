import _get from "lodash/get";
import _map from "lodash/map";
import { call, put, race, select, take } from "redux-saga/effects";

import { dashboardDefaultRole, updateObject } from "Helpers/utils";
import { setFilterRole as setFilterRoleAction, setPrimaryModalValues } from "Pages/dashboard/dashboard.actions";
import { ReportsActionTypes } from "Pages/reports-browser/reports-browser.actions";
import type { IFavorite } from "SP/favorites/favorites.types";
import type { IReportExtended } from "SP/reports/reports.types";
import { IGroupedTags } from "SP/tags/tags.types";
import UsersService from "SP/users";
import type { IUserExtended } from "SP/users/users.types";
import { FavoritesActionTypes } from "Store/actions/favorites.actions";
import { TagsActionTypes } from "Store/actions/tags.actions";
import { updateCurrentUser, UsersActionsTypes } from "Store/actions/users.actions";
import type { IRootReducerState } from "Store/reducers";

const usersService = new UsersService();

const reportsSelectors = {
  allReports: (state: IRootReducerState): IReportExtended[] => state.reports.allReports,
  isAllReportsLoaded: (state: IRootReducerState): boolean => state.reports.isAllReportsLoaded,
};
const usersSelectors = {
  currentUser: (state: IRootReducerState) => state.users.currentUser,
};
const favoritesSelectors = {
  favorites: (state: IRootReducerState) => state.favorites.favorites,
  isFavoritesLoaded: (state: IRootReducerState): boolean => state.favorites.isFavoritesLoaded,
};

const tagsSelectors = {
  groupedTags: (state: IRootReducerState) => state.tags.groupedTags,
};

export function* updateCurrentUserFavoritesOrder(favoriteReportsIds: number[]) {
  try {
    const currentUser: IUserExtended = yield select(usersSelectors.currentUser);

    if (currentUser.configs) {
      yield call([usersService, "updateCurrentUserFavoritesOrder"], favoriteReportsIds, currentUser);
      const configs = updateObject(currentUser.configs, { favoritesOrder: favoriteReportsIds });
      yield put(updateCurrentUser({ configs }));
    } else {
      const configs = yield call([usersService, "addCurrentUserFavoritesOrder"], favoriteReportsIds, currentUser);
      yield put(updateCurrentUser({ configs }));
    }
  } catch (error) {
    throw error;
  }
}

export function* getFavoritesOrder() {
  const currentUser: IUserExtended = yield select(usersSelectors.currentUser);
  const favorites: IFavorite[] = yield select(favoritesSelectors.favorites);
  const favoritesOrder = _get(currentUser.configs, "favoritesOrder", null);

  if (favoritesOrder === null) {
    return _map(favorites, "reportId");
  }

  return favoritesOrder;
}

const DEPRECATED_DEFAULT_ROLE = "All roles";

export function* getFilterRole() {
  let currentUser: IUserExtended = yield select(usersSelectors.currentUser);

  if (!currentUser) {
    const { success } = yield race({
      success: take(UsersActionsTypes.GET_CURRENT_USER_SUCCESS),
      failure: take(UsersActionsTypes.GET_CURRENT_USER_FAILURE),
    });
    currentUser = success?.payload || {};
  }

  const filterRole = _get(currentUser.configs, "filterRole", null);

  let chosenRoles = filterRole?.chosenRoles;
  // deprecated option was selected:
  const chosenRole = filterRole?.chosenRole;

  if (!chosenRoles && chosenRole) {
    if (chosenRole === DEPRECATED_DEFAULT_ROLE) {
      chosenRoles = [dashboardDefaultRole.id];
    } else {
      chosenRoles = [chosenRole];
    }
  }
  if (chosenRoles) {
    yield put(setFilterRoleAction(chosenRoles));
  } else {
    const groupedTags: IGroupedTags = yield waitForGroupedTags();

    const primaryRole = yield call([usersService, "getPrimaryRole"], currentUser.email, groupedTags.Role);
    const newPrimaryRoles = primaryRole ? [primaryRole] : [dashboardDefaultRole.id];

    yield put(setPrimaryModalValues(newPrimaryRoles));
  }
}

export function* setFilterRoles(roles: string[]) {
  const currentUser: IUserExtended = yield select(usersSelectors.currentUser);

  if (currentUser.configs) {
    yield call([usersService, "updateCurrentUserFilterRole"], roles, currentUser);
    const configs = updateObject(currentUser.configs, { filterRole: { chosenRoles: roles } });
    yield put(updateCurrentUser({ configs }));
  } else {
    const configs = yield call([usersService, "addCurrentUserFilterRole"], roles, currentUser);
    yield put(updateCurrentUser({ configs }));
  }
}

export function* waitForGroupedTags() {
  let groupedTags: IGroupedTags = yield select(tagsSelectors.groupedTags);

  if (!groupedTags) {
    const { success } = yield race({
      success: take(TagsActionTypes.GET_TAGS_SUCCESS),
      failure: take(TagsActionTypes.GET_TAGS_FAILURE),
    });
    groupedTags = success?.groupedTags || {};
  }

  return groupedTags;
}

export function* waitForAllReports() {
  let allReports = yield select(reportsSelectors.allReports);
  const isAllReportsLoaded = yield select(reportsSelectors.isAllReportsLoaded);

  if (!isAllReportsLoaded) {
    const { success } = yield race({
      success: take(ReportsActionTypes.GET_ALL_REPORTS_SUCCESS),
      failure: take(ReportsActionTypes.GET_ALL_REPORTS_FAILURE),
    });
    allReports = success?.reports || [];
  }

  return allReports;
}

export function* waitForFavorites() {
  let favorites: IFavorite[] = yield select(favoritesSelectors.favorites);
  const isFavoritesLoaded = yield select(favoritesSelectors.isFavoritesLoaded);

  if (!isFavoritesLoaded) {
    const { success } = yield race({
      success: take(FavoritesActionTypes.GET_CURRENT_USER_FAVORITES_SUCCESS),
      failure: take(FavoritesActionTypes.GET_CURRENT_USER_FAVORITES_FAILURE),
    });
    favorites = success?.payload || [];
  }

  return favorites;
}
