import produce from "immer";
import _isEqual from "lodash/isEqual";
import _remove from "lodash/remove";
import { Reducer } from "redux";

import { DashboardActionTypes, IDashboardActionTypes } from "Pages/dashboard/dashboard.actions";
import { ReportActionTypes } from "Pages/report/report.actions";
import type { IReport, IReportExtended } from "SP/reports/reports.types";
import { FavoritesActionTypes } from "Store/actions/favorites.actions";

type IRoleRelatedReports = Record<string, IReport[]>;
export interface IDashboardState {
  favoriteReports: IReport[];
  roleRelatedReports: IRoleRelatedReports;
  filterRoles: string[];
  primaryRoleModalValues: string[];
  loading: boolean;
  isLoaded: boolean;
}

const initialState: IDashboardState = {
  favoriteReports: [],
  roleRelatedReports: {},
  filterRoles: [],
  primaryRoleModalValues: [],
  loading: false,
  isLoaded: false,
};

const handleFavoriteReport = (draft: IDashboardState, favoriteReport: IReportExtended) => {
  if (favoriteReport) {
    draft.favoriteReports.unshift(favoriteReport);
  }
};

const findReportById = (reportId: number) => (report: IReport) => report.id === reportId;

const dashboardReducer: Reducer<IDashboardState, IDashboardActionTypes> = produce((draft, action) => {
  switch (action.type) {
    case DashboardActionTypes.GET_DASHBOARD_REPORTS_REQUEST:
      draft.loading = true;
      draft.isLoaded = false;
      break;
    case DashboardActionTypes.GET_DASHBOARD_REPORTS_SUCCESS:
      draft.loading = false;
      draft.isLoaded = true;
      draft.favoriteReports = action.favoriteReports;
      draft.roleRelatedReports = action.roleRelatedReports;
      break;
    case DashboardActionTypes.GET_DASHBOARD_REPORTS_FAILURE:
      draft.loading = false;
      draft.isLoaded = true;
      break;
    case DashboardActionTypes.SET_FILTER_ROLES:
      draft.filterRoles = action.filterRoles;
      break;
    case DashboardActionTypes.SET_PRIMARY_MODAL_VALUES:
      draft.primaryRoleModalValues = action.primaryRoleModalValues;
      break;

    case DashboardActionTypes.REORDER_FAVORITE_REPORTS:
      draft.favoriteReports = action.favoriteReports;
      break;

    case FavoritesActionTypes.FAVORITE_REPORT_SUCCESS:
      handleFavoriteReport(draft, action.report);
      break;

    case FavoritesActionTypes.UNFAVORITE_REPORT_SUCCESS:
      _remove(draft.favoriteReports, findReportById(action.reportId));
      break;

    case ReportActionTypes.DELETE_REPORT_SUCCESS:
      _remove(draft.favoriteReports, findReportById(action.reportId));

      Object.keys(draft.roleRelatedReports).forEach((role) => {
        if (draft.roleRelatedReports[role]) {
          _remove(draft.roleRelatedReports[role], findReportById(action.reportId));
        }
      });
      break;
  }
}, initialState);

export default dashboardReducer;
