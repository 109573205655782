import _cloneDeep from "lodash/cloneDeep";
import _map from "lodash/map";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { updateReportsSearchRank } from "Helpers/reportsHelper";
import { dashboardDefaultRole } from "Helpers/utils";
import { useGlobalSearchData } from "Hooks/useGlobalSearch";
import { IFavorite } from "SP/favorites/favorites.types";
import { IReport, IReportExtended, IReportFields, ISort } from "SP/reports/reports.types";
import { IRootReducerState } from "Store/reducers";

interface IReportWithPriority extends IReportExtended {
  priority: number;
}

interface IUseReportsSort {
  sort: ISort;
  sortedReports: IReport[];
  setSort: React.Dispatch<React.SetStateAction<ISort>>;
}

function handleSortBySearchRank(a: IReportExtended, b: IReportExtended, asc: boolean) {
  if (!asc) {
    return handleSortBySearchRank(b, a, true);
  }

  if (a.searchRank !== b.searchRank) return a.searchRank - b.searchRank;
  if (a.orderNumber !== b.orderNumber) return a.orderNumber - b.orderNumber;
  return handleSortByDate(a, b, asc);
}

function addReportsPriority(reports: IReportExtended[], favoriteReportIds: number[]): IReportWithPriority[] {
  const newReports = [];

  reports.forEach((report) => {
    if (favoriteReportIds.includes(report.id) && report.certified) {
      Object.assign(report, { priority: 4 });
    } else if (favoriteReportIds.includes(report.id)) {
      Object.assign(report, { priority: 3 });
    } else if (report.certified) {
      Object.assign(report, { priority: 2 });
    } else {
      Object.assign(report, { priority: 1 });
    }

    newReports.push(report);
  });

  return newReports;
}

function handleSortByPriority(a: IReportWithPriority, b: IReportWithPriority, asc: boolean) {
  if (!asc) {
    return handleSortByPriority(b, a, true);
  }

  if (a.priority !== b.priority) return a.priority - b.priority;
  if (a.priority === 1 && b.priority === 1 && a.orderNumber !== b.orderNumber) return a.orderNumber - b.orderNumber;
  return handleSortByDate(a, b, asc);
}

function handleSortByDate(a: IReport, b: IReport, asc: boolean) {
  if (!asc) {
    return handleSortByDate(b, a, true);
  }

  return +new Date(a.modificationDate) - +new Date(b.modificationDate);
}

export const getSpecificRoleNamesOrder = (nameA: string, nameB: string) => {
  if (nameA === dashboardDefaultRole.id) {
    return -1;
  }

  if (nameB === dashboardDefaultRole.id) {
    return 1;
  }

  if (nameA < nameB) {
    return -1;
  }

  if (nameA > nameB) {
    return 1;
  }

  return 0;
};

function handleSortByName(a: IReport, b: IReport, asc: boolean) {
  if (!asc) {
    return handleSortByName(b, a, true);
  }

  const nameA = a.reportName.toLowerCase();
  const nameB = b.reportName.toLowerCase();

  if (nameA < nameB) {
    return -1;
  }

  if (nameA > nameB) {
    return 1;
  }

  return 0;
}

export const getSortedReports = (
  reports: IReport[],
  favorites: IFavorite[],
  isAllReports: boolean,
  isSearchTextExist: boolean,
  sort: ISort,
) => {
  const newReports = _cloneDeep(reports);

  if (sort?.field === IReportFields.ReportName) {
    return newReports.sort((a, b) => handleSortByName(a, b, sort.asc));
  } else if (sort?.field === IReportFields.UserDefinedModificationDate) {
    return newReports.sort((a, b) => handleSortByDate(a, b, sort.asc));
  }

  const favoriteReportIds = _map(favorites, "reportId");

  if (isSearchTextExist && isAllReports) {
    const reportsWithRank = updateReportsSearchRank(newReports, favoriteReportIds);
    return reportsWithRank.sort((a, b) => handleSortBySearchRank(a, b, sort.asc));
  }

  const reportsWithPriority = addReportsPriority(newReports, favoriteReportIds);
  return reportsWithPriority.sort((a, b) => handleSortByPriority(a, b, sort.asc));
};

export const useReportsSort = (reports: IReport[], isAllReports = false): IUseReportsSort => {
  const [sort, setSort] = useState<ISort>({ field: IReportFields.Certified, asc: false });
  const favorites = useSelector((state: IRootReducerState) => state.favorites.favorites);
  const { isSearchTextExist } = useGlobalSearchData();

  const sortedReports = useMemo(() => {
    return getSortedReports(reports, favorites, isAllReports, isSearchTextExist, sort);
  }, [reports, isAllReports, favorites, isSearchTextExist, sort]);

  return { sort, sortedReports, setSort };
};

interface IUseDashboardReportsSort {
  sortedReports: Record<string, IReport[]>;
}

export const useDashboardReportsSort = (reports: Record<string, IReport[]>): IUseDashboardReportsSort => {
  const favorites = useSelector((state: IRootReducerState) => state.favorites.favorites);

  const sortedReports = useMemo(() => {
    const reportsKeys = Object.keys(reports);
    const result: Record<string, IReport[]> = {};

    reportsKeys.forEach((key) => {
      result[key] =
        reports[key] &&
        getSortedReports(reports[key], favorites, false, false, {
          field: IReportFields.Certified,
          asc: false,
        });
    });

    return result;
  }, [reports, favorites]);

  return { sortedReports };
};
