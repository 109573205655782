import { useDispatch, useSelector } from "react-redux";

import { IReport } from "SP/reports/reports.types";
import { IRootReducerState } from "Store/reducers";

import {
  getDashboardReports,
  getFilterRole,
  reorderFavoriteReports,
  setFilterRole,
  setPrimaryModalValues,
} from "./dashboard.actions";

export const useDashboardFavorites = () => {
  const dispatch = useDispatch();
  const loading = useSelector<IRootReducerState, boolean>((state) => state.dashboard.loading);
  const isLoaded = useSelector<IRootReducerState, boolean>((state) => state.dashboard.isLoaded);
  const favoriteReports = useSelector<IRootReducerState, IReport[]>((state) => state.dashboard.favoriteReports);

  const handleReorderFavoriteReports = (newFavoriteReports: IReport[]) => {
    dispatch(reorderFavoriteReports(newFavoriteReports));
  };

  return {
    loading,
    isLoaded,
    favoriteReports,
    reorderFavoriteReports: handleReorderFavoriteReports,
  };
};

export const useDashboardReportsData = () => {
  const loading = useSelector<IRootReducerState, boolean>((state) => state.dashboard.loading);
  const roleRelatedReports = useSelector<IRootReducerState, Record<string, IReport[]>>(
    (state) => state.dashboard.roleRelatedReports,
  );
  const filterRoles = useSelector<IRootReducerState, string[]>((state) => state.dashboard.filterRoles);
  const primaryRoleModalValues = useSelector<IRootReducerState, string[]>(
    (state) => state.dashboard.primaryRoleModalValues,
  );

  return {
    loading,
    roleRelatedReports,
    filterRoles,
    primaryRoleModalValues,
  };
};

export const useDashboardReportsMethods = () => {
  const dispatch = useDispatch();
  const handleGetDashboardReports = () => dispatch(getDashboardReports());
  const handleSetFilterRoles = (filterRoles: string[]) => dispatch(setFilterRole(filterRoles || []));
  const handleGetFilterRole = () => dispatch(getFilterRole());
  const handleSetPrimaryModalValues = (values: string[]) => dispatch(setPrimaryModalValues(values));

  return {
    getDashboardReports: handleGetDashboardReports,
    setFilterRoles: handleSetFilterRoles,
    getFilterRole: handleGetFilterRole,
    setPrimaryModalValues: handleSetPrimaryModalValues,
  };
};
