import { parseJson } from "Helpers/utils";
import { ITagWithGroup } from "SP/tags/tags.types";
import UsersRepository from "SP/users/users.repository";
import type {
  ISearchEntityDTO,
  IUser,
  IUserConfigs,
  IUserConfigsDTO,
  IUserDTO,
  IUserEntityDTO,
  IUserExtended,
} from "SP/users/users.types";

export default class UsersService {
  private repository = new UsersRepository();

  private mapPeople = (user: ISearchEntityDTO): IUser => {
    const userId = user.Key as number;

    switch (user.EntityType || user.EntityData?.PrincipalType) {
      case "User":
        return {
          name: user.DisplayText,
          email: user.EntityData.Email || user.Description,
          id: isNaN(userId) ? +user.EntityData?.SPUserID : userId,
        };
      case "SecGroup":
      case "FormsRole":
        return {
          id: userId,
          name: user.DisplayText,
          email: user.EntityData?.Email || user.ProviderName,
        };
      default:
        return {
          id: +user.EntityData.SPGroupID,
          name: user.DisplayText,
          email: user.EntityData.AccountName,
        };
    }
  };

  private mapUser = (user: IUserEntityDTO): IUser => ({
    name: user.Title,
    email: user.Email,
    id: user.Id,
  });

  private mapConfigs = (configs: IUserConfigsDTO): IUserConfigs => ({
    id: configs.Id,
    favoritesOrder: parseJson<number[]>(configs.FavoritesOrder),
    filterRole: parseJson<Record<string, any>>(configs.FilterRole),
  });

  private mapUserExtended = (user: IUserDTO): IUserExtended => ({
    ...this.mapUser(user.entity),
    photo: user.photo,
    permissions: user.permissions,
    configs: user.configs ? this.mapConfigs(user.configs) : null,
  });

  public async getCurrentUser(): Promise<IUserExtended> {
    const user = await this.repository.getCurrentUser();
    return this.mapUserExtended(user);
  }

  public async getPrimaryRole(email: string, rolesTags: ITagWithGroup[]): Promise<string> {
    const roles = await this.repository.getCurrentUserRoles(email);
    const rolesTagsSet = new Set(rolesTags.map((roleTag) => roleTag.name));

    const primaryRole = roles
      ?.sort((a, b) => a.rank - b.rank)
      .find((roleData) => rolesTagsSet.has(roleData.name))?.name;

    return primaryRole;
  }

  public async addCurrentUserFavoritesOrder(
    favoriteReportsIds: number[],
    currentUser: IUserExtended,
  ): Promise<IUserConfigs> {
    const { data } = await this.repository.addCurrentUserConfigs({
      FavoritesOrder: JSON.stringify(favoriteReportsIds),
      UserId: currentUser.id,
    });

    return this.mapConfigs(data);
  }

  public async updateCurrentUserFavoritesOrder(
    favoriteReportsIds: number[],
    currentUser: IUserExtended,
  ): Promise<void> {
    await this.repository.updateCurrentUserConfigs(
      {
        FavoritesOrder: JSON.stringify(favoriteReportsIds),
      },
      currentUser.configs.id,
    );
  }

  public async updateCurrentUserFilterRole(filterRoles: string[], currentUser: IUserExtended): Promise<void> {
    await this.repository.updateCurrentUserConfigs(
      {
        FilterRole: JSON.stringify({ chosenRoles: filterRoles }),
      },
      currentUser.configs.id,
    );
  }

  public async addCurrentUserFilterRole(filterRoles: string[], currentUser: IUserExtended): Promise<IUserConfigs> {
    const { data } = await this.repository.addCurrentUserConfigs({
      FilterRole: JSON.stringify({ chosenRoles: filterRoles }),
      UserId: currentUser.id,
    });

    return this.mapConfigs(data);
  }

  public async getUsersByIds(ids: number[]): Promise<IUser[]> {
    if (ids.length === 0) return [];
    const users = await this.repository.getUsersByIds(ids);
    return users.map(this.mapUser);
  }

  public async getUsersByNames(names: string[]): Promise<IUser[]> {
    if (names.length === 0) return [];
    const users = await this.repository.getUsersByNames(names);

    const mappedUsers = [];

    for (const user of users) {
      if (user.data) {
        mappedUsers.push(this.mapPeople(user.data));
      } else {
        // In case if user name is present in the report as a owner and can't be found by 'clientPeoplePicker'
        const missedUser = await this.search(user.text, 1);
        missedUser[0] && mappedUsers.push(missedUser[0]);
      }
    }

    return mappedUsers;
  }

  public async search(name: string, limit?: number): Promise<IUser[]> {
    const users = await this.repository.search(name, limit || 10);
    return users.map(this.mapUser);
  }

  public async searchPeople(name: string, limit?: number): Promise<IUser[]> {
    if (name.length > 2) {
      const users = await this.repository.searchPeople(name, limit || 10);
      return users.map(this.mapPeople);
    }

    return [];
  }

  public async searchPeopleByEmails(emails: string[]): Promise<IUser[]> {
    const users = await Promise.all(emails.map((email) => this.searchPeople(email, 2)));
    return users.flat();
  }
}
