import { Formik, FormikErrors } from "formik";
import _isEqual from "lodash/isEqual";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import useDebouncedCallback from "use-debounce/lib/useDebouncedCallback";

import { svc } from "@App/services";
import { FlexCell, FlexRow, LabeledInput, Panel, RadioGroup } from "@epam/loveship";

import {
  DatePickerField,
  InputField,
  SelectField,
  SwitchField,
  UploadFileField,
  UploadFileInputField,
} from "Components/fields";
import { RolesField } from "Components/fields/roles-field/roles-field";
import PathSelectModal from "Components/path-select-modal";
import { ISelectedPath } from "Components/path-select-modal/path-select-modal";
import withNaming from "Helpers/bemClassname";
import { useTagsData } from "Hooks/useTags";
import { useUsersData } from "Hooks/useUsers";
import { panelStyles } from "Pages/add-report/add-report";
import { REPORT_LABELS } from "Pages/report/report-details/report-details-helpers";
import { editReportRequest, handleCreateReport } from "Pages/report/report.actions";
import { IReportEditSavePayload } from "SP/reports/reports.types";
import { checkIsDocumentUniqueByName, checkIsReportUniqueByName } from "SP/reports/reports.utils";

import { UserSelectField } from "./add-report-form.fields";
import { IAddReportFormProps, IFormRef, Info, ReportFormFieldTypes } from "./add-report-form.types";
import generateAddReportSchema from "./add-report-form.validation";
import { CustomTooltip, renderLabel } from "./add-report-label-tooltip";

import "./style.scss";

const cn = withNaming("add-report-form");

function isReportFileUploaded(v: IReportEditSavePayload): boolean {
  return !v.reportLink && v.reportUploadFile !== null;
}

const handlePathModalClose = (formRef: IFormRef) => () => {
  const { touched } = formRef.current;
  if (!touched[ReportFormFieldTypes.REPORT_FOLDER_PATH]) {
    formRef.current.setFieldTouched(ReportFormFieldTypes.REPORT_FOLDER_PATH, true);
  }
};

const handleOpenPathModal = async (formRef: IFormRef, onSave) => {
  return svc.uuiModals.show((modalProps) => (
    <PathSelectModal modalProps={modalProps} onClose={handlePathModalClose(formRef)} onSave={onSave} />
  ));
};

const handleFilterCondition = (day: moment.Moment) => {
  return (
    day.valueOf() >= moment().subtract(2, "years").subtract(1, "days").valueOf() && day.valueOf() <= moment().valueOf()
  );
};

const handleVideoGuideLink = (value: string) => {
  const srcRegex = /src=(?:"|')?([^"']*)(?:"|')?/;
  const srcMatch = value.match(srcRegex);

  if (srcMatch) {
    return srcMatch[1];
  }

  return value;
};

const handleReportLink = (value: string) => {
  return value.trim();
};

const DELAY = process.env.UNIT_TEST ? 0 : 300;

const AddReportForm: React.FC<IAddReportFormProps> = ({
  initialValues,
  formRef,
  isValidForm,
  onReportNameSet,
  onAfterSubmit,
  onSetValidForm,
  onSetFormDirty,
  id,
  isEdit,
  permissions,
}) => {
  const dispatch = useDispatch();
  const [reportMode, setReportMode] = useState(1);
  const isUniqueNameRef = useRef(true);
  const isUniqueDocNameRef = useRef(true);
  const { domains, applications, metrics } = useTagsData();
  const { currentUser } = useUsersData();

  const canEditPermissions = permissions?.canDelete || !id;
  const attachedFileName = initialValues.file?.name || null;
  const canCertifyReport = useMemo(() => currentUser?.permissions.canCertifyReport, [currentUser]);

  useEffect(() => {
    if (isEdit && !initialValues.reportLink) {
      setReportMode(2);
    }
  }, [initialValues.reportLink, isEdit]);

  const handleReportModeChange = (mode: number): void => {
    const resetField = mode === 1 ? ReportFormFieldTypes.REPORT_UPLOAD_FILE : ReportFormFieldTypes.REPORT_LINK;
    const resetFieldInitialValue = formRef.current.initialValues[resetField];
    formRef.current.setFieldValue(resetField, resetFieldInitialValue);
    setReportMode(mode);
  };

  const handleFolderPathChange = async (path: ISelectedPath, validateForm: () => Promise<void>) => {
    await formRef.current.setFieldValue(ReportFormFieldTypes.REPORT_FOLDER_PATH, path);

    if (attachedFileName) {
      formRef.current.getFieldHelpers(ReportFormFieldTypes.REPORT_UPLOAD_FILE).setTouched(true);
    }

    validateForm();
  };

  const handleSubmit = (values: IReportEditSavePayload) => {
    if (isEdit) {
      dispatch(editReportRequest(id, values, onAfterSubmit));
    } else {
      dispatch(handleCreateReport(reportMode, values, onAfterSubmit));
    }
  };

  const debouncedCheckNameRequest = useDebouncedCallback(
    async (name: string, validateForm: () => Promise<FormikErrors<IReportEditSavePayload>>) => {
      isUniqueNameRef.current = await checkIsReportUniqueByName(name);
      await validateForm();
    },
    DELAY,
  );

  const checkIsUniqueName = useCallback(
    (name: string, validateForm: () => Promise<FormikErrors<IReportEditSavePayload>>): string => {
      isUniqueNameRef.current = true;
      if (name === initialValues.reportName) return name;

      debouncedCheckNameRequest.callback(name, validateForm);

      return name;
    },
    [debouncedCheckNameRequest],
  );

  const checkIsUniqueDocName = useCallback(
    async (validateForm: (values?: any) => Promise<FormikErrors<IReportEditSavePayload>>) => {
      const initialLocation = initialValues.folderPath?.destinationUrl;
      const newLocation = formRef.current?.values.folderPath?.destinationUrl;

      if (initialLocation === newLocation) {
        isUniqueDocNameRef.current = true;
      } else {
        const fileName = attachedFileName || formRef.current?.values.reportUploadFile?.name;
        const folderId = formRef.current?.values?.folderPath?.key;

        isUniqueDocNameRef.current = await checkIsDocumentUniqueByName(fileName, folderId);
      }

      validateForm();
    },
    [attachedFileName],
  );

  const handleValidate = (v: IReportEditSavePayload) => {
    const isFormChanged = !_isEqual(initialValues, v);
    const isValid =
      generateAddReportSchema(reportMode, attachedFileName, isUniqueNameRef, isUniqueDocNameRef).isValidSync(v) &&
      isFormChanged;

    onReportNameSet(v.reportName);
    onSetFormDirty(isFormChanged);
    if (isValidForm !== isValid) {
      onSetValidForm(isValid);
    }
  };

  useEffect(() => {
    if (isReportFileUploaded(initialValues)) {
      setReportMode(2);
    }
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={generateAddReportSchema(reportMode, attachedFileName, isUniqueNameRef, isUniqueDocNameRef)}
      innerRef={(ref) => (formRef.current = ref)}
      validate={handleValidate}
    >
      {({ validateForm }) => {
        const debouncedNameValidation = (value: string) => checkIsUniqueName(value, validateForm);
        const docNameValidation = () => checkIsUniqueDocName(validateForm);
        return (
          <>
            <Panel background="white" style={panelStyles}>
              <div className={cn("", ["form"])}>
                <h2 className="form__block-title">General</h2>
                <FlexRow cx={cn("row", ["form__row"])}>
                  <FlexCell cx={cn("cell")} grow={1}>
                    <LabeledInput label={renderLabel("Report Name", "")}>
                      <InputField
                        maxLength={100}
                        name="reportName"
                        placeholder="Enter Report Name"
                        handler={debouncedNameValidation}
                      />
                    </LabeledInput>
                  </FlexCell>
                  <FlexCell cx={cn("cell", ["switch-cell", "flex"])} grow={1}>
                    <SwitchField name="certified" label="Certified" isDisabled={!canCertifyReport} />
                    <CustomTooltip content={canCertifyReport ? Info.Certified : Info.DisabledCertified} />
                  </FlexCell>
                </FlexRow>
                <FlexRow cx={cn("row", ["form__row"])}>
                  <FlexCell cx={cn("cell")} grow={1}>
                    <LabeledInput label={renderLabel("Owner", Info.Owner)}>
                      <UserSelectField fieldName={ReportFormFieldTypes.REPORT_OWNER} placeholder="Select Owner" />
                    </LabeledInput>
                  </FlexCell>
                  <FlexCell cx={cn("cell")} grow={1}>
                    <LabeledInput label={renderLabel("Modification date", Info.ModificationDate)}>
                      <DatePickerField
                        isDisableInputChange
                        name="modificationDate"
                        placeholder="Enter Value"
                        filterCondition={handleFilterCondition}
                      />
                    </LabeledInput>
                  </FlexCell>
                </FlexRow>
                <FlexRow cx={cn("row", ["form__row"])}>
                  <FlexCell cx={cn("cell", ["max-grow-half"])}>
                    <LabeledInput label={renderLabel("Link to KB description", Info.LinkToKb)}>
                      <InputField name="kbLink" placeholder="Please input the link to KB description" />
                    </LabeledInput>
                  </FlexCell>
                </FlexRow>
                <FlexRow cx={cn("row", ["form__row"])}>
                  <FlexCell cx={cn("cell", ["max-grow-half"])}>
                    <LabeledInput label={renderLabel("Report Video Guide", Info.ReportVideoGuide, false)}>
                      <InputField
                        name="videoGuideLink"
                        placeholder="Enter link to the Video Portal/SharePoint Stream"
                        handler={handleVideoGuideLink}
                      />
                    </LabeledInput>
                  </FlexCell>
                </FlexRow>
                {!isEdit && (
                  <FlexRow cx={cn("row", ["form__row", cn("radio-group-wrapper")])}>
                    <FlexCell cx={cn("cell")} grow={1}>
                      <RadioGroup
                        items={[
                          { id: 1, name: REPORT_LABELS.linkToTheReport },
                          { id: 2, name: "Upload report" },
                        ]}
                        cx={cn("radio-group")}
                        value={reportMode}
                        onValueChange={handleReportModeChange}
                        direction="horizontal"
                      />
                    </FlexCell>
                  </FlexRow>
                )}
                <FlexRow cx={cn("row", ["form__row"])}>
                  {reportMode === 1 ? (
                    <FlexCell cx={cn("cell", ["max-grow-half"])}>
                      <LabeledInput label={renderLabel(REPORT_LABELS.linkToTheReport, Info.LinkToTheReport)}>
                        <InputField
                          handler={handleReportLink}
                          name={ReportFormFieldTypes.REPORT_LINK}
                          placeholder="Enter Value"
                        />
                      </LabeledInput>
                    </FlexCell>
                  ) : (
                    <FlexCell cx={cn("cell")} grow={1}>
                      <LabeledInput label={renderLabel("Upload report", Info.UploadReport)}>
                        <UploadFileField
                          name={ReportFormFieldTypes.REPORT_UPLOAD_FILE}
                          fileInfo={initialValues.file}
                          onChange={docNameValidation}
                        />
                      </LabeledInput>
                    </FlexCell>
                  )}
                </FlexRow>
                <FlexRow cx={cn("row", ["form__row"])}>
                  <FlexCell cx={cn("cell", ["max-grow-half"])}>
                    <LabeledInput
                      label={renderLabel("Report Location", Info.ReportLocation)}
                      cx="upload-file-input__labeled-input"
                    >
                      <UploadFileInputField
                        name={ReportFormFieldTypes.REPORT_FOLDER_PATH}
                        placeholder="Select Report Location"
                        onIconClick={() =>
                          handleOpenPathModal(formRef, (path) => handleFolderPathChange(path, docNameValidation))
                        }
                      />
                    </LabeledInput>
                  </FlexCell>
                </FlexRow>
                <FlexRow cx={cn("row", ["form__row"])}>
                  <FlexCell cx={cn("cell", ["max-grow-half"])}>
                    <LabeledInput label={renderLabel("Power BI ID", Info.PowerBiId, false)}>
                      <InputField name="powerBI_ID" placeholder="Enter PowerBI ID" />
                    </LabeledInput>
                  </FlexCell>
                </FlexRow>
                <FlexRow cx={cn("row", ["form__row"])}>
                  <FlexCell cx={cn("cell")} grow={1}>
                    <LabeledInput label={renderLabel("Description", Info.Description)}>
                      <InputField
                        name="description"
                        placeholder="Enter Description"
                        type="textarea"
                        rows={4}
                        maxLength={1500}
                      />
                    </LabeledInput>
                  </FlexCell>
                </FlexRow>
                <FlexRow cx={cn("row", ["form__row"])}>
                  <FlexCell cx={cn("cell")} grow={1}>
                    <LabeledInput label={renderLabel("Applications", Info.Applications)}>
                      <SelectField
                        withCopy
                        name="applications"
                        options={applications}
                        placeholder="Select Applications"
                      />
                    </LabeledInput>
                  </FlexCell>
                  <FlexCell cx={cn("cell")} grow={1}>
                    <LabeledInput label={renderLabel("Business Domains", Info.BusinessDomains)}>
                      <SelectField withCopy name="domains" options={domains} placeholder="Select Business Domains" />
                    </LabeledInput>
                  </FlexCell>
                </FlexRow>
                <FlexRow cx={cn("row", ["form__row"])}>
                  <FlexCell cx={cn("cell")} grow={1}>
                    <LabeledInput label={renderLabel("Metrics", Info.Metrics)}>
                      <SelectField withCopy name="metrics" options={metrics} placeholder="Select Metrics" />
                    </LabeledInput>
                  </FlexCell>
                  <FlexCell cx={cn("cell")} grow={1}>
                    <LabeledInput label={renderLabel("Roles", Info.Roles)}>
                      <RolesField />
                    </LabeledInput>
                  </FlexCell>
                </FlexRow>
              </div>
            </Panel>
            {canEditPermissions && (
              <Panel background="white" style={{ ...panelStyles, marginTop: 24 }}>
                <div className={cn("", ["form"])}>
                  <FlexRow>
                    <h2 className="form__block-title">Permissions</h2>
                    <CustomTooltip content={Info.Permissions} cx="form__block-title" />
                  </FlexRow>
                  <FlexRow cx={cn("row", ["form__row"])}>
                    <LabeledInput label="Read only">
                      <UserSelectField fieldName={ReportFormFieldTypes.REPORT_VIEW_ONLY_USERS} />
                    </LabeledInput>
                  </FlexRow>
                  <FlexRow cx={cn("row", ["form__row"])}>
                    <LabeledInput label="Read and Download">
                      <UserSelectField fieldName={ReportFormFieldTypes.REPORT_READ_ONLY_USERS} />
                    </LabeledInput>
                  </FlexRow>
                  <FlexRow cx={cn("row", ["form__row"])}>
                    <LabeledInput label="Edit report and its description">
                      <UserSelectField fieldName={ReportFormFieldTypes.REPORT_MODIFIER_USERS} />
                    </LabeledInput>
                  </FlexRow>
                  <FlexRow cx={cn("row", ["form__row"])}>
                    <LabeledInput label="Edit report, its description and permissions">
                      <UserSelectField fieldName={ReportFormFieldTypes.REPORT_CONTRIBUTOR_USERS} />
                    </LabeledInput>
                  </FlexRow>
                </div>
              </Panel>
            )}
          </>
        );
      }}
    </Formik>
  );
};

export default AddReportForm;
