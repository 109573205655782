import React from "react";

import type { ITimePeriod } from "Pages/reports-browser/reports-filters.reducer";
import type { IFile } from "SP/reports/reports.types";
import { IGroupedTagNames, ITagWithGroup, TagGroupNames, TagGroupUINames } from "SP/tags/tags.types";

import Routes from "../routes";

export const isLocalHost =
  window.location.hostname === "localhost" ||
  window.location.hostname === "[::1]" ||
  window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/);

export function hash(str: string): number {
  let hash = 5381,
    i = str.length;

  while (i) {
    hash = (hash * 33) ^ str.charCodeAt(--i);
  }

  /* JavaScript does bitwise operations (like XOR, above) on 32-bit signed
   * integers. Since we want the results to be always positive, convert the
   * signed int to an unsigned by doing an unsigned bitshift. */
  return hash >>> 0;
}

export function handleHistoryBack(history) {
  if (history.length > 2) {
    history.goBack();
  } else {
    history.push(Routes.HOME.path);
  }
}

// same as lodash.noop, but Component;
export const EmptyRC = (): React.ReactNode => <></>;

export function htmlDecode(str: string): string {
  const e = document.createElement("textarea");
  e.innerHTML = str;
  return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
}

export function stripTags(str: string): string {
  const div = document.createElement("div");
  div.innerHTML = str;
  return div.textContent || div.innerText || "";
}

export function groupTags(tags: ITagWithGroup[], tagKey: keyof ITagWithGroup = "name"): IGroupedTagNames {
  const groupedTags = {};

  tags.forEach((tag) => {
    const tagGroupName = tag.group === TagGroupNames.Domain ? TagGroupUINames.Domain : tag.group;
    groupedTags[tagGroupName] = groupedTags[tagGroupName] || [];
    groupedTags[tagGroupName].push(tag[tagKey]);
  });

  return groupedTags;
}

export function truncateString(str: string, limit: number): string {
  if (str.length <= limit) return str;

  let truncated = str.substr(0, limit);
  const breakPoint = truncated.lastIndexOf(" ");

  if (breakPoint >= 0 && breakPoint < str.length - 1) {
    truncated = truncated.substr(0, breakPoint);
  }

  return truncated + "...";
}

export function handleArrayChecker<T>(list: T[]): T[] {
  return Array.isArray(list) ? list : [];
}

export function downloadFileByUniqID(id: string): void {
  window.open(`${process.env.REACT_APP_BASE_URL}/_layouts/download.aspx?UniqueId=${id}`, "_blank");
}

export function isSSRSFile(url: string): boolean {
  if (!url) return;

  return url.indexOf(".rdl") !== -1 || url.indexOf(".rsds") !== -1;
}

export function generateOnPremiseUrl(url: string): string {
  if (!url) return;

  const ServerRelativeUrl = getServerRelativeUrl();
  const newUrl = url.replace(ServerRelativeUrl, "");
  return `${process.env.REACT_APP_ON_PREMISE_URL}/_layouts/15/ReportServer/RSViewerPage.aspx?rv:RelativeReportUrl=${newUrl}`;
}

export function downloadFile(fileInfo: IFile, canDownload: boolean): void {
  if (isSSRSFile(fileInfo?.url)) {
    window.open(generateOnPremiseUrl(fileInfo.url), "_blank");
    return;
  }

  if (fileInfo?.uniqueId && canDownload) {
    downloadFileByUniqID(fileInfo.uniqueId);
  }
}

export function isShortLink(link: string): boolean {
  if (!link) return;

  const regx = /^https?/;
  return !regx.test(link);
}

function fallbackCopyTextToClipboard(text: string, cb: () => void): void {
  let textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand("copy");
    successful && cb();
  } catch (err) {
    console.error("Fallback: Oops, unable to copy", err);
  }

  document.body.removeChild(textArea);
  textArea = null;
}

export async function copyTextToClipboard(text: string, cb: () => void): Promise<void> {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text, cb);
    return;
  }

  navigator.clipboard.writeText(text).then(cb, (err) => {
    console.error("Async: Could not copy text: ", err);
  });
}

export function isCanSelectPickerInput<T>(oldValues: T[], newValues: T[]): boolean {
  return Array.isArray(newValues) || (oldValues.length > 0 && !newValues);
}

export function isUsernamesFromQuery(names: (string | number)[]): boolean {
  return Array.isArray(names) && typeof names[0] === "string";
}

export function omitEmptyDate(timePeriod: ITimePeriod): Partial<ITimePeriod> {
  if (timePeriod.fromDate && !timePeriod.toDate) {
    return {
      fromDate: timePeriod.fromDate,
    };
  } else if (!timePeriod.fromDate && timePeriod.toDate) {
    return {
      toDate: timePeriod.toDate,
    };
  }

  return timePeriod;
}

export function getServerRelativeUrl(): string {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  return baseUrl.split("com")[1];
}

export function isLastElement<T>(elementIndex: number, elements: T[]): boolean {
  return elementIndex === elements.length - 1;
}

export function handleShortLink(link: string): string {
  if (!link) return;

  return isShortLink(link) ? `https://${link}` : link;
}

export function getBase64ImageFromBlob(photoBlob: Blob): Promise<string> {
  return new Promise((res) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      res(reader.result as string);
    });
    reader.readAsDataURL(photoBlob);
  });
}

export function parseJson<T>(str: string): T {
  try {
    JSON.parse(str);
  } catch (e) {
    return str as unknown as T;
  }
  return JSON.parse(str);
}

export function updateObject<T, U>(obj1: T, obj2: U): T & U {
  return { ...obj1, ...obj2 };
}

export function getSearchSegments(search: string): string[] {
  return search.toLowerCase().split(/\s+/);
}

export function isNumber(n): boolean {
  return !isNaN(parseFloat(n)) && !isNaN(n - 0);
}

// log errors without toString method
export function logStringifiedError(description: string, e: any): void {
  try {
    console.log(description, JSON.stringify(e));
  } catch (error) {
    console.log("Error logging failed. Description: ", description, "Fail reason: ", error, "Error data: ", e);
  }
}

export interface IRole {
  id: string;
  label: string;
}

export const dashboardDefaultRole = { id: "Any role", label: "Any role" };

export function handleRolesData(roles: IRole[]): IRole[] {
  return [dashboardDefaultRole, ...roles.map((role) => ({ id: role.label, label: role.label }))];
}
