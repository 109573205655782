import React, { useMemo } from "react";

import { ReactComponent as kbIcon } from "@App/assets/kb.svg";
import { ReactComponent as videoGuideIcon } from "@App/assets/video_guide.svg";
import { IconButton, Tooltip } from "@epam/loveship";

import ReportContextMenu from "Components/report-context-menu";
import { DEFAULT_MENU_WIDTH } from "Components/report-context-menu/report-context-menu";
import { handleOpenVideoGuideModal } from "Components/video-guide-modal";
import { ViewReportButton } from "Components/view-report-button";
import withNaming from "Helpers/bemClassname";
import { REPORT_LABELS } from "Pages/report/report-details/report-details-helpers";

import "./report-buttons.scss";

interface IReportButtonsProps {
  reportId: number;
  reportName: string;
  kbLink: string;
  videoGuideLink?: string;
  showVideoGuide?: boolean;
  hideDelete?: boolean;
  hideShow?: boolean;
}

const EXTENDED_MENU_WIDTH = 165;
const cn = withNaming("report-buttons");

export const ReportButtons = ({
  reportId,
  reportName,
  kbLink,
  videoGuideLink,
  showVideoGuide,
  hideDelete = false,
  hideShow = false,
}: IReportButtonsProps): JSX.Element => {
  const videoGuideLinkInContextMenu = useMemo(
    () => !showVideoGuide && videoGuideLink,
    [showVideoGuide, videoGuideLink],
  );

  return (
    <div className={cn("", ["flex items-center"])}>
      {!hideShow && <ViewReportButton reportName={reportName} />}
      <Tooltip placement="bottom" content={REPORT_LABELS.linkToKB}>
        <IconButton cx={cn("report-kb-link")} target="_blank" icon={kbIcon} href={kbLink} />
      </Tooltip>
      {showVideoGuide && videoGuideLink && (
        <Tooltip placement="bottom" content={REPORT_LABELS.viewVideoTutorial}>
          <IconButton
            cx="video-guide-icon flex items-center"
            icon={videoGuideIcon}
            onClick={() => handleOpenVideoGuideModal(reportId)}
          />
        </Tooltip>
      )}
      <ReportContextMenu
        isShowDelete={!hideDelete}
        dropdownWidth={videoGuideLinkInContextMenu ? EXTENDED_MENU_WIDTH : DEFAULT_MENU_WIDTH}
        reportId={reportId}
        reportName={reportName}
        videoGuideLink={videoGuideLinkInContextMenu}
      />
    </div>
  );
};
