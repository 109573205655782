import React, { createContext, useContext } from "react";

import { ITagSelectOption, useTagsData } from "Hooks/useTags";
import type { IGroupedTags, ITagWithGroup } from "SP/tags/tags.types";

type ITagsContext = {
  groupedTags: IGroupedTags;
  allTags: ITagWithGroup[];
  roles: ITagSelectOption[];
  domains: ITagSelectOption[];
  applications: ITagSelectOption[];
  loading: boolean;
  getReportTagIds: (tagIds: string[]) => {
    roles: string[];
    domains: string[];
    applications: string[];
    metrics: string[];
  };
  getReportTags: (tagIds: string[]) => {
    roles: ITagSelectOption[];
    domains: ITagSelectOption[];
    applications: ITagSelectOption[];
    metrics: ITagSelectOption[];
  };
  metrics: ITagSelectOption[];
};

export const TagsContext = createContext<ITagsContext>(null);

export const TagsProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const value = useTagsData();

  return <TagsContext.Provider value={value}>{children}</TagsContext.Provider>;
};

export const useTagsContext = (): ITagsContext => {
  const context = useContext(TagsContext);

  if (!context) {
    throw new Error("useTagsContext must be used within a TagsProvider and TagsContext");
  }

  return context;
};
