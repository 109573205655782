import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

import Routes from "@App/routes";
import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { arrayMove, rectSortingStrategy, SortableContext, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { LinkButton, Spinner } from "@epam/loveship";

import { Accordion } from "Components/accordion";
import type { IAccordionHandle } from "Components/accordion/accordion";
import withNaming from "Helpers/bemClassname";
import { defaultDropAnimationConfig, touchSensorOptions } from "Helpers/dndHelper";
import { FavoritesExpand } from "Helpers/storage";
import { DashboardFilterTabs } from "Pages/dashboard/dashboard-domain-filters/dashboard-domain-filters";
import { useDashboardFavorites } from "Pages/dashboard/dashboard.hooks";
import type { IReport } from "SP/reports/reports.types";

import { FavoriteReport, SortableFavoriteReport } from "./dashboard-favorites-block-report";

import "./dashboard-favorites-block.scss";

export const cn = withNaming("dashboard-block");

const DashboardFavoritesBlock = () => {
  const accordionRef = useRef<IAccordionHandle>();
  const [activeId, setActiveId] = useState<number | null>(null);
  const { loading, isLoaded, favoriteReports, reorderFavoriteReports } = useDashboardFavorites();

  const getIndex = (id: number) => favoriteReports.findIndex((report) => report.id === id);
  const activeIndex = activeId ? getIndex(activeId) : -1;

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor, touchSensorOptions),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  function handleDragStart({ active }) {
    if (!active) {
      return;
    }

    setActiveId(active.id);
  }

  function handleDragEnd({ over }) {
    setActiveId(null);

    if (over) {
      const overIndex = getIndex(over.id);

      if (activeIndex !== overIndex) {
        reorderFavoriteReports(arrayMove<IReport>(favoriteReports, activeIndex, overIndex));
      }
    }
  }

  useEffect(() => {
    if (isLoaded) {
      const storedExpand = FavoritesExpand.get();
      const isExpanded = favoriteReports.length && (storedExpand ?? true);
      accordionRef.current.handleSetIsExpanded(isExpanded);
    } else {
      accordionRef.current.handleSetIsExpanded(true);
    }
  }, [isLoaded, favoriteReports.length]);

  return (
    <Accordion
      defaultExpanded
      expandStorage={FavoritesExpand}
      isDisabled={isLoaded && favoriteReports.length === 0}
      ref={accordionRef}
      badgeCount={favoriteReports.length}
      title="Favorite"
      renderTitle={(title, isDisabled) => (
        <LinkButton
          cx={cn("title", { isDisabled }, ["unstyled-link"])}
          caption={title}
          link={{
            pathname: Routes.REPORTS_BROWSER.path,
            search: `&filterBy=${DashboardFilterTabs.favorite}`,
          }}
        />
      )}
    >
      <div className={cn("", ["inline-flex flex-wrap"])}>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onDragCancel={() => setActiveId(null)}
        >
          <SortableContext items={favoriteReports} strategy={rectSortingStrategy}>
            {favoriteReports.map((report) => (
              <SortableFavoriteReport key={report.id} report={report} />
            ))}
          </SortableContext>
          {createPortal(
            <DragOverlay adjustScale dropAnimation={defaultDropAnimationConfig}>
              {activeId ? <FavoriteReport dragOverlay report={favoriteReports[activeIndex]} /> : null}
            </DragOverlay>,
            document.body,
          )}
        </DndContext>
        {loading && <Spinner cx={cn("loader")} />}
      </div>
    </Accordion>
  );
};

export default DashboardFavoritesBlock;
