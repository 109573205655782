import React from "react";

import { ReactComponent as fileIcon } from "@App/assets/file.svg";
import { ReactComponent as excelIcon } from "@epam/assets/icons/common/file-file_excel-24.svg";
import { ReactComponent as pdfIcon } from "@epam/assets/icons/common/file-file_pdf-24.svg";
import { ReactComponent as textIcon } from "@epam/assets/icons/common/file-file_text-24.svg";
import { ReactComponent as wordIcon } from "@epam/assets/icons/common/file-file_word-24.svg";
import { ReactComponent as closeIcon } from "@epam/assets/icons/common/navigation-close-18.svg";
import { IconButton, IconContainer } from "@epam/loveship";

import withNaming from "Helpers/bemClassname";
import { downloadFile } from "Helpers/utils";
import type { IFile } from "SP/reports/reports.types";

const icons = {
  txt: textIcon,
  pdf: pdfIcon,
  docx: wordIcon,
  xlsx: excelIcon,
};

interface IFileBadgeProps {
  onClose?: (e) => void;
  canDownload?: boolean;
  attachedFile?: File;
  fileInfo?: IFile;
  error?: string;
}

function getFileType(fileName: string) {
  const pieces = fileName.split(/[\s.]+/);
  const type = pieces[pieces.length - 1];
  return type.split("?")[0];
}

function renderFileTypeAndSize(file: File, type: string) {
  if (!file) {
    return type;
  }
  const mbSize = file.size / 1024 / 1024;
  return `${type}, ${mbSize.toFixed(2)} MB`;
}

function addBadgeClassNames(fileInfo: IFile, canDownload: boolean, isHaveClose: boolean, error: boolean): string[] {
  const classNames = [];

  error && classNames.push("not-valid");

  if (fileInfo && !canDownload) {
    classNames.push("cursor-not-allowed");
  } else if (fileInfo && canDownload && !isHaveClose) {
    classNames.push("cursor-pointer");
  }

  return classNames;
}

const FileBadge: React.FC<IFileBadgeProps> = ({ onClose, attachedFile, fileInfo, canDownload, error }) => {
  const cn = withNaming("upload-file-field-badge");

  const fileName = attachedFile?.name || fileInfo?.name || "";
  const fileType = getFileType(fileName);

  return (
    <div
      className={cn("", addBadgeClassNames(fileInfo, canDownload, !!onClose, !!error))}
      onClick={() => downloadFile(fileInfo, canDownload)}
    >
      <IconContainer cx={cn("extension-icon", { [fileType]: true })} icon={icons[fileType] || fileIcon} />
      <div className={cn("info")}>
        <div className={cn("info-name", ["cut-text"])}>{fileName}</div>
        <div className={cn("info-type-size", ["cut-text"])}>{renderFileTypeAndSize(attachedFile, fileType)}</div>
      </div>
      {onClose && <IconButton cx={cn("remove-icon")} icon={closeIcon} onClick={onClose} />}
    </div>
  );
};

export default FileBadge;
