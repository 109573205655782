import { useEffect } from "react";

import { handleShowPrimaryRoleModal } from "Components/primary-role-modal/primary-role-modal";
import { useDashboardReportsData } from "Pages/dashboard/dashboard.hooks";

export const usePrimaryRoleModal = (): void => {
  const { primaryRoleModalValues } = useDashboardReportsData();

  useEffect(() => {
    primaryRoleModalValues.length && handleShowPrimaryRoleModal();
  }, [primaryRoleModalValues]);
};
