import CamlBuilder, { IExpression, IQuery, ViewScope } from "camljs";
import _isEqual from "lodash/isEqual";

import { FileAddResult, Item, List, PermissionKind, RenderListDataParameters, sp } from "@pnp/sp";
import { IContentTypeInfo, IFolderInfo } from "@pnp/sp-commonjs";
import { setItemMetaDataMultiField } from "@pnp/sp-taxonomy";

import { batchPromises } from "Helpers/batchPromises";
import { hash } from "Helpers/utils";
import cache, { localStorageGetAllPrefix } from "SP/cache";
import TagsService from "SP/tags";

import {
  ACCEPTABLE_FILE_CHUNK_SIZE,
  ALL_REPORTS_SELECTOR,
  DEFAULT_FILE_CHUNK_SIZE,
  REPORT_VERSIONS_SELECTOR,
} from "./reports.constants";
import {
  IEditReportParams,
  IEditReportPermissionsParams,
  IFile,
  IReportDTO,
  IReportFields,
  IReportFileVersionDTO,
  IReportIdentifierType,
  IReportPermissions,
  IReportVersionDTO,
  IRoleAssignmentUsersWithDefId,
  IStreamReportDTO,
  IStreamReportsDTO,
  IUsersWithResult,
} from "./reports.types";
import {
  addGuidLabel,
  getRoleAssignmentUsers,
  isRoleAssignmentsUsersEmpty,
  isRoleAssignmentsUsersExists,
  mapReportFile,
} from "./reports.utils";

export default class ReportsRepository {
  private dl: List = sp.web.defaultDocumentLibrary;
  private tags: TagsService = new TagsService();
  private readonly roleDefinitions = {
    "View Only": "ViewOnly",
    Read: "Read",
    "Contribute without Delete": "Modify",
    "Full Control": "FullControl",
  };

  private readonly metadataFields = {
    Tags: "Tags",
    PrimaryRoles: "PrimaryRoles",
  };

  public readonly adminGroups = {
    ReportAdminReaders: "Report Admin Readers",
    ReportAdminMembers: "Report Admin Members",
    ReportAdminOwners: "Report Admin Owners",
  };

  private readonly roleDefinitionIds = {
    ViewOnly: null,
    Read: null,
    Modify: null,
    FullControl: null,
  };

  public mandatoryRoleAssignments = {
    ContributorsId: { results: [] },
    ModifiersId: { results: [] },
    ReadOnlyUsersId: { results: [] },
    ViewOnlyUsersId: { results: [] },
  };

  chainFilters = (query: IQuery | IExpression) => {
    if ("Where" in query) {
      return query.Where();
    } else {
      return query.And();
    }
  };

  generateFilterByTechnicalQuery = (query: IQuery | IExpression) => {
    return this.chainFilters(query).IntegerField(IReportFields.IsTechnical).NotEqualTo(1);
  };

  generateFilterByReportNameQuery = (query: IQuery | IExpression) => {
    return this.chainFilters(query).TextField(IReportFields.ReportName).IsNotNull();
  };

  generateFilterByOwnerQuery = (query: IQuery | IExpression) => {
    return this.chainFilters(query).LookupMultiField(IReportFields.Owner).IsNotNull();
  };

  getAll(): Promise<IStreamReportsDTO> {
    const baseQuery = new CamlBuilder().View(ALL_REPORTS_SELECTOR).Scope(ViewScope.Recursive).RowLimit(5000).Query();

    const filteredByTechnicalQuery = this.generateFilterByTechnicalQuery(baseQuery);
    const filteredByReportNameQuery = this.generateFilterByReportNameQuery(filteredByTechnicalQuery);
    const filteredByOwnerQuery = this.generateFilterByOwnerQuery(filteredByReportNameQuery);

    const caml: RenderListDataParameters = {
      ViewXml: filteredByOwnerQuery.ToString(),
      DatesInUtc: true,
    };

    return cache.staleWhileRevalidate({
      type: "reports",
      key: `${localStorageGetAllPrefix}:${hash("reports")}`,
      promise: this.dl.renderListDataAsStream.bind(this.dl),
      promiseParams: caml,
      isUseLocalStorage: true,
    });
  }

  async getByIdentifier(identifier: string, identifierType: IReportIdentifierType): Promise<IStreamReportDTO> {
    const baseQuery = new CamlBuilder().View(ALL_REPORTS_SELECTOR).Scope(ViewScope.Recursive).RowLimit(1).Query();
    let ViewXml = "";

    if (identifierType === "name") {
      ViewXml = baseQuery.Where().TextField(IReportFields.ReportName).EqualTo(identifier).ToString();
    } else if (identifierType === "id") {
      ViewXml = baseQuery
        .Where()
        .NumberField(IReportFields.ID)
        .EqualTo(+identifier)
        .ToString();
    }

    const { Row } = await this.dl.renderListDataAsStream({ ViewXml, DatesInUtc: true });
    const report: IStreamReportDTO = Row[0];

    if (!report) {
      const err = new Error("Not found");
      Object.defineProperty(err, "status", { value: 404 });
      throw err;
    }

    return report;
  }

  async getReportVideoGuideById(id: number): Promise<IReportDTO> {
    return this.dl.items.getById(id).select(IReportFields.ReportName, IReportFields.ReportVideoGuide).get();
  }

  async getPermissionsOnItem(reportId: number): Promise<IReportPermissions> {
    const reportPerms = await this.dl.items.getById(reportId).getCurrentUserEffectivePermissions();

    return {
      canDelete: sp.web.hasPermissions(reportPerms, PermissionKind.DeleteListItems),
      canEdit: sp.web.hasPermissions(reportPerms, PermissionKind.EditListItems),
      canDownload: sp.web.hasPermissions(reportPerms, PermissionKind.OpenItems),
    };
  }

  async getFileInfo(id: number): Promise<IFile> {
    const file = await this.dl.items.getById(id).file.get();
    return mapReportFile(file);
  }

  async getReportVersionHistory(reportId: number) {
    const [reportVersions, reportFileVersions, currentFile] = await Promise.all([
      this.dl.items
        .getById(reportId)
        .versions.select(...REPORT_VERSIONS_SELECTOR)
        .get<IReportVersionDTO[]>(),
      this.dl.items.getById(reportId).file.versions.select("Length", "VersionLabel").get<IReportFileVersionDTO[]>(),
      this.dl.items.getById(reportId).file.select("Length").get<{ Length: string }>(),
    ]);

    return { reportVersions, reportFileVersions, currentFileSize: currentFile.Length };
  }

  async setReportTags(report, tagIds, field = this.metadataFields.Tags): Promise<void> {
    const tags = await Promise.all(tagIds.map((tagId) => this.tags.getTagById(tagId as string)));
    if (!tags.length) {
      tags.push({});
    }
    await setItemMetaDataMultiField(report, field, ...tags);
  }

  async assignDefinedRoleDefinitionsId(): Promise<void> {
    if (Object.values(this.roleDefinitionIds).filter(Boolean).length > 0) return;

    const defs = await sp.web.roleDefinitions.usingCaching().get();

    defs.forEach((def) => {
      const roleDefinitionName = this.roleDefinitions[def.Name];
      if (roleDefinitionName) {
        this.roleDefinitionIds[roleDefinitionName] = def.Id;
      }
    });
  }

  public async getMandatoryGroupsIds() {
    const [reportAdminOwners, reportAdminMembers, reportAdminReaders] = await Promise.all([
      sp.web.siteGroups.getByName(this.adminGroups.ReportAdminOwners).usingCaching().get(),
      sp.web.siteGroups.getByName(this.adminGroups.ReportAdminMembers).usingCaching().get(),
      sp.web.siteGroups.getByName(this.adminGroups.ReportAdminReaders).usingCaching().get(),
    ]);

    this.mandatoryRoleAssignments.ContributorsId.results = [reportAdminOwners.Id];
    this.mandatoryRoleAssignments.ModifiersId.results = [reportAdminMembers.Id];
    this.mandatoryRoleAssignments.ReadOnlyUsersId.results = [reportAdminReaders.Id];
  }

  getRoleAssignmentUsersWithDefIds(payload: Partial<IReportDTO>): IRoleAssignmentUsersWithDefId[] {
    return [
      { users: payload.ViewOnlyUsersId, defId: this.roleDefinitionIds.ViewOnly },
      { users: payload.ReadOnlyUsersId, defId: this.roleDefinitionIds.Read },
      { users: payload.ModifiersId, defId: this.roleDefinitionIds.Modify },
      {
        users: payload.ContributorsId,
        defId: this.roleDefinitionIds.FullControl,
      },
    ];
  }

  async addRoleAssignmentsUsers(report: Item, userIds: number[], defId: number): Promise<void> {
    await batchPromises({
      items: userIds,
      fn: (userId) => {
        return report.roleAssignments.add(userId, defId);
      },
    });
  }

  async addReportRoleAssignments(
    report: Item,
    payload: Partial<IReportDTO>,
    isBreakRoleInheritance: boolean,
  ): Promise<void> {
    if (isRoleAssignmentsUsersEmpty(payload)) return;

    await this.assignDefinedRoleDefinitionsId();

    let batchPromisesItems = this.getRoleAssignmentUsersWithDefIds(payload);

    if (isBreakRoleInheritance) {
      await Promise.all([report.breakRoleInheritance(), this.getMandatoryGroupsIds()]);

      batchPromisesItems = [
        ...batchPromisesItems,
        ...this.getRoleAssignmentUsersWithDefIds(this.mandatoryRoleAssignments),
      ];
    }

    await batchPromises({
      items: batchPromisesItems,
      fn: ({ users, defId }) => {
        return this.addRoleAssignmentsUsers(report, (users as IUsersWithResult).results, defId);
      },
    });
  }

  async removeRoleAssignmentsUsers(report: Item, userIds: number[], defId: number): Promise<void> {
    await batchPromises({
      items: userIds,
      fn: (userId) => {
        return report.roleAssignments.remove(userId, defId);
      },
    });
  }

  async removeReportRoleAssignments(report: Item, payload: Partial<IReportDTO>): Promise<void> {
    if (isRoleAssignmentsUsersEmpty(payload)) return;

    await this.assignDefinedRoleDefinitionsId();

    await batchPromises({
      items: this.getRoleAssignmentUsersWithDefIds(payload),
      fn: ({ users, defId }) => {
        return this.removeRoleAssignmentsUsers(report, (users as IUsersWithResult).results, defId);
      },
    });
  }

  async editPermissions({ report, payload, oldPayload }: IEditReportPermissionsParams): Promise<void> {
    // Cover case if new roles are assigned to the report with inherited roles
    if (isRoleAssignmentsUsersEmpty(oldPayload) && isRoleAssignmentsUsersExists(payload)) {
      await report.resetRoleInheritance(); // In case if report has unique roles but in document row properties are missing
      await this.addReportRoleAssignments(report, payload, true);
      // Cover case if all roles are removed from the report that has assigned ones
    } else if (isRoleAssignmentsUsersEmpty(payload) && isRoleAssignmentsUsersExists(oldPayload)) {
      await report.resetRoleInheritance();
      // Cover case if new roles are added and old roles are removed from the report
    } else if (isRoleAssignmentsUsersExists(oldPayload) && isRoleAssignmentsUsersExists(payload)) {
      const { removedUsers, addedUsers } = getRoleAssignmentUsers({ payload, oldPayload });
      await this.removeReportRoleAssignments(report, { ...payload, ...removedUsers });
      await this.addReportRoleAssignments(report, { ...payload, ...addedUsers }, false);
    }
  }

  private isTagsFieldChanged(oldFieldVal: string[], newFieldVal: string[]) {
    return !_isEqual(
      [...oldFieldVal].sort(),
      newFieldVal.map((tagId) => (tagId.includes("Guid") ? tagId : addGuidLabel(tagId))).sort(),
    );
  }

  private isPayloadChanged(oldPayload: Partial<IReportDTO>, newPayload: Partial<IReportDTO>) {
    const oldPayloadWithoutTags = { ...oldPayload };
    delete oldPayloadWithoutTags.TagsBI;
    delete oldPayloadWithoutTags.PrimaryRoles;

    return !_isEqual(oldPayloadWithoutTags, newPayload);
  }

  async edit({ id, file, payload, oldPayload, destinationUrls, canEditPermissions }: IEditReportParams): Promise<void> {
    cache.clear("reports");
    const tags = [...payload.TagsBI];
    const primaryRolesTags = [...payload.PrimaryRoles];
    delete payload.TagsBI;
    delete payload.PrimaryRoles;

    const report = await this.dl.items.getById(id);

    if (file) {
      await report.file.setContentChunked(file);
    }

    if (file || this.isPayloadChanged(oldPayload, payload)) {
      await report.update(payload);
    }

    if (this.isTagsFieldChanged(oldPayload.TagsBI as string[], tags as string[])) {
      await this.setReportTags(report, tags);
    }

    if (this.isTagsFieldChanged(oldPayload.PrimaryRoles, primaryRolesTags)) {
      await this.setReportTags(report, primaryRolesTags, this.metadataFields.PrimaryRoles);
    }

    if (destinationUrls) {
      await sp.web.getFileByServerRelativeUrl(destinationUrls.originalUrl).moveTo(destinationUrls.newUrl);
    }

    if (canEditPermissions) {
      await this.editPermissions({ report, payload, oldPayload });
    }
  }

  async deleteById(id: number): Promise<string> {
    cache.clear("reports");
    return await this.dl.items.getById(id).recycle();
  }

  async create(file: File, folderId: string, payload: Partial<IReportDTO>): Promise<number> {
    cache.clear("reports");
    const tags = [...payload.TagsBI];
    const primaryRolesTags = [...payload.PrimaryRoles];
    delete payload.TagsBI;
    delete payload.PrimaryRoles;

    const fileAddResult: FileAddResult = await this.uploadChunkedFile(file.name, file, folderId);

    const report: Item = await fileAddResult.file.getItem();
    await report.update(payload);

    await this.setReportTags(report, tags);
    await this.setReportTags(report, primaryRolesTags, this.metadataFields.PrimaryRoles);
    await this.addReportRoleAssignments(report, payload, true);

    return (report as Partial<IReportDTO>).Id;
  }

  async createLink(payload: Partial<IReportDTO>, folderId: string): Promise<number> {
    cache.clear("reports");
    const tags = [...payload.TagsBI];
    const primaryRolesTags = [...payload.PrimaryRoles];
    delete payload.TagsBI;
    delete payload.PrimaryRoles;

    const contentTypeId: string = await this.getLinkToDocumentContentTypeId();
    const fileName: string = payload.ReportName + ".aspx";
    const file: string = this.getLinkToDocumentTemplate(payload.ReportLink);
    const fileAddResult: FileAddResult = await this.uploadFile(fileName, file, folderId);
    const report: Item = await fileAddResult.file.getItem();
    await report.update({ ContentTypeId: contentTypeId, ...payload });

    await this.setReportTags(report, tags);
    await this.setReportTags(report, primaryRolesTags, this.metadataFields.PrimaryRoles);
    await this.addReportRoleAssignments(report, payload, true);

    return (report as Partial<IReportDTO>).Id;
  }

  async uploadFile(fileName: string, file: File | string, folderId: string): Promise<FileAddResult> {
    return await sp.web.getFolderById(folderId).files.add(fileName, file, true);
  }

  async uploadChunkedFile(fileName: string, file: File, folderId: string): Promise<FileAddResult> {
    if (file.size < DEFAULT_FILE_CHUNK_SIZE) {
      return await this.uploadFile(fileName, file, folderId);
    }

    const progress = (data) => {
      console.info(`Upload: "${fileName}", Progress: ${((data.blockNumber / data.totalBlocks) * 100).toFixed()}%`);
    };

    return await sp.web
      .getFolderById(folderId)
      .files.addChunked(fileName, file, progress, true, ACCEPTABLE_FILE_CHUNK_SIZE);
  }

  async getFolderUniqueId(folder: string): Promise<string> {
    const folderInfo: IFolderInfo = await sp.web.getFolderByServerRelativeUrl(folder).get();
    return folderInfo.UniqueId;
  }

  async getLinkToDocumentContentTypeId(): Promise<string> {
    const contentTypes: IContentTypeInfo[] = await this.dl.contentTypes
      .filter("Name eq 'Link to a Document'")
      .top(1)
      .get();
    return contentTypes.length > 0 ? contentTypes[0].StringId : null;
  }

  getLinkToDocumentTemplate(url: string): string {
    return `<html><head><meta http-equiv="refresh" content="0;url=${url}" /></head><body></body></html>`;
  }
}
