import React, { FC, useEffect, useState } from "react";

import { SearchInput } from "@epam/loveship";

import { SynonymWords } from "Components/synonym-words";
import withNaming from "Helpers/bemClassname";
import { useMobileData } from "Hooks/useMobile";

import { useFilterContext } from "../filter-context";

import "./report-browser-search.scss";

const cn = withNaming("reports-browser-search");

const ReportsBrowserSearch: FC = () => {
  const { query, setSearchText } = useFilterContext();
  const [value, setValue] = useState(query.search || null);
  const { isMobile } = useMobileData();

  const handleSubmit = (e) => {
    if (e.keyCode !== 13) return;
    setSearchText(value);
  };

  const onCancel = () => {
    setValue("");
    setSearchText("");
  };

  useEffect(() => {
    if (!value && !!query.search) {
      setValue(query.search);
    }
  }, [query.search]);

  return (
    <div className={cn("", ["flex flex-col items-start"])}>
      <SearchInput
        autoFocus={!isMobile}
        disableDebounce
        cx={cn("input")}
        value={value}
        onValueChange={setValue}
        placeholder="Search report by name, description and tags"
        onKeyDown={handleSubmit}
        {...(value && { onCancel })}
      />
      <SynonymWords />
    </div>
  );
};

export default ReportsBrowserSearch;
