import React, { createContext, useContext } from "react";

import type { ISelectOption } from "Components/select/select.types";
import { useFilters } from "Hooks/useFilters";
import type { ITagWithGroup } from "SP/tags/tags.types";
import type { IUser } from "SP/users/users.types";

import { IFilterTypes } from "./reports-filters.actions";
import { ITimePeriod } from "./reports-filters.reducer";

type IFilterContext = {
  getUsersByIds: (ids: (number | string)[]) => IUser[];
  query: any;
  filterBy: string[];
  timePeriod: ITimePeriod;
  searchText: string;
  groupedTagOptions: Record<string, any>;
  ownerOptions: ISelectOption[];
  getSelectedTagsToRender: (selectedTags: string[]) => ITagWithGroup[];
  removeTimePeriodItem: (timePeriodKey: keyof ITimePeriod) => () => void;
  removeFilterByItem: (filterByItem: string) => () => void;
  removeSelectedFilter: (filterId: string) => () => void;
  setAllFilters: (filters: IFilterTypes, isUpdateQuery?: boolean) => void;
  setSearchText: (value: string) => void;
  setTimePeriod: (values: ITimePeriod) => void;
  setFiltersBy: (values: string[]) => void;
  setSelectedOwners: (values: number[]) => void;
  setSelectedTags: (values: string[]) => void;
  selectedOwners: number[];
  selectedTags: Set<string>;
  removeSelectedOwner: (ownerId: number) => () => void;
};

export const FilterContext = createContext<IFilterContext>(null);

export const FilterProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const value = useFilters();

  return <FilterContext.Provider value={value}>{children}</FilterContext.Provider>;
};

export const useFilterContext = (): IFilterContext => {
  const context = useContext(FilterContext);

  if (!context) {
    throw new Error("useFilterContext must be used within a FilterProvider and FilterContext");
  }

  return context;
};
