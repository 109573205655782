import type { IDeleteReportSuccessAction } from "Pages/report/report.actions";
import type { IReport } from "SP/reports/reports.types";
import type { IFavoriteReportSuccessAction, IUnfavoriteReportSuccessAction } from "Store/actions/favorites.actions";

export enum DashboardActionTypes {
  GET_DASHBOARD_REPORTS_REQUEST = "[DASHBOARD] GET DASHBOARD REPORTS",
  GET_DASHBOARD_REPORTS_SUCCESS = "[DASHBOARD] GET DASHBOARD REPORTS SUCCESS",
  GET_DASHBOARD_REPORTS_FAILURE = "[DASHBOARD] GET DASHBOARD REPORTS FAILURE",

  SET_FILTER_ROLES = "[DASHBOARD] SET FILTER ROLES",

  GET_FILTER_ROLE = "[DASHBOARD] GET FILTER ROLE",

  SET_PRIMARY_MODAL_VALUES = "[DASHBOARD] SET_PRIMARY_MODAL_VALUES",

  REORDER_FAVORITE_REPORTS = "[DASHBOARD] REORDER FAVORITE REPORTS",
}

// GET DASHBOARD REPORTS
export interface IGetDashboardReportsRequestAction {
  type: DashboardActionTypes.GET_DASHBOARD_REPORTS_REQUEST;
}

export function getDashboardReports(): IGetDashboardReportsRequestAction {
  return {
    type: DashboardActionTypes.GET_DASHBOARD_REPORTS_REQUEST,
  };
}

export interface IGetDashboardReportsSuccessAction {
  type: DashboardActionTypes.GET_DASHBOARD_REPORTS_SUCCESS;
  favoriteReports: IReport[];
  roleRelatedReports: Record<string, IReport[]>;
}

export function getDashboardReportsSuccess(
  favoriteReports: IReport[],
  roleRelatedReports: Record<string, IReport[]>,
): IGetDashboardReportsSuccessAction {
  return {
    type: DashboardActionTypes.GET_DASHBOARD_REPORTS_SUCCESS,
    favoriteReports,
    roleRelatedReports,
  };
}

export interface IGetDashboardReportsFailureAction {
  type: DashboardActionTypes.GET_DASHBOARD_REPORTS_FAILURE;
  error: string;
}

export function getDashboardReportsFailure(error: Error): IGetDashboardReportsFailureAction {
  return {
    type: DashboardActionTypes.GET_DASHBOARD_REPORTS_FAILURE,
    error: error.message,
  };
}

// GET FILTER ROLE
export interface IGetFilterRoleAction {
  type: DashboardActionTypes.GET_FILTER_ROLE;
}

export function getFilterRole(): IGetFilterRoleAction {
  return {
    type: DashboardActionTypes.GET_FILTER_ROLE,
  };
}

export interface ISetPrimaryModalValueAction {
  type: DashboardActionTypes.SET_PRIMARY_MODAL_VALUES;
  primaryRoleModalValues: string[];
}

export function setPrimaryModalValues(primaryRoleModalValues: string[]): ISetPrimaryModalValueAction {
  return {
    type: DashboardActionTypes.SET_PRIMARY_MODAL_VALUES,
    primaryRoleModalValues,
  };
}

// SET FILTERS
export interface ISetFilterRolesAction {
  type: DashboardActionTypes.SET_FILTER_ROLES;
  filterRoles: string[];
}

export function setFilterRole(filterRoles: string[]): ISetFilterRolesAction {
  return {
    type: DashboardActionTypes.SET_FILTER_ROLES,
    filterRoles,
  };
}

export interface IReorderFavoriteReportsAction {
  type: DashboardActionTypes.REORDER_FAVORITE_REPORTS;
  favoriteReports: IReport[];
}

export function reorderFavoriteReports(favoriteReports: IReport[]): IReorderFavoriteReportsAction {
  return {
    type: DashboardActionTypes.REORDER_FAVORITE_REPORTS,
    favoriteReports,
  };
}

export type IDashboardActionTypes =
  | IGetDashboardReportsRequestAction
  | IGetDashboardReportsSuccessAction
  | IGetDashboardReportsFailureAction
  | ISetFilterRolesAction
  | IReorderFavoriteReportsAction
  | IFavoriteReportSuccessAction
  | IUnfavoriteReportSuccessAction
  | IDeleteReportSuccessAction
  | IGetFilterRoleAction
  | ISetPrimaryModalValueAction;
